<template>
  <div class="h-backgroud-color_body">
    <van-sticky>
      <div v-if="spread">
        <van-cell-group>
          <van-field v-model="frameNo" clearable label="车架号" maxlength="17" placeholder="请输入车架号" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="listConfig.form.customerName" clearable label="客户名称" placeholder="请输入客户名称" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="listConfig.form.phone" type="digit" clearable label="手机号" maxlength="11" placeholder="请输入手机号" />
        </van-cell-group>
        <van-cell-group>
          <van-field v-model="plateNo" clearable label="车牌号" maxlength="8" placeholder="请输入车牌号" />
        </van-cell-group>
        <div class="h-p-10">
          <van-button type="primary" block round @click="onSearch">确认</van-button>
        </div>
      </div>
      <div v-else>
        <van-dropdown-menu>
          <van-dropdown-item title="筛选" ref="searchRef">
            <div>
              <van-cell-group>
                <van-field v-model="frameNo" clearable label="车架号" maxlength="17" placeholder="请输入车架号" />
              </van-cell-group>
              <van-cell-group>
                <van-field v-model="listConfig.form.customerName" clearable label="客户名称" placeholder="请输入客户名称" />
              </van-cell-group>
              <van-cell-group>
                <van-field v-model="listConfig.form.phone" type="digit" clearable label="手机号" maxlength="11" placeholder="请输入手机号" />
              </van-cell-group>
              <van-cell-group>
                <van-field v-model="plateNo" clearable label="车牌号" maxlength="8" placeholder="请输入车牌号" />
              </van-cell-group>
              <div class="h-p-10">
                <van-button type="primary" block round @click="onSearch">确认</van-button>
              </div>
            </div>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
    </van-sticky>
    <div :class="{ spread: spread }">
      <HYList ref="HYList" v-bind="listConfig">
        <template #columnHead="{ row }">
          <div class="row">
            <div class="row-head">
              <span>销售日期：{{ row.saleDate && dayjs(row.saleDate).format("YYYY-MM-DD") }}</span>
            </div>
            <div class="row-content">
              <div class="h-font-md h-flex">
                <span class="row-content_title">合同号：</span>
                <span>{{ row.contractNo }}</span>
              </div>
              <div class="h-font-md h-flex">
                <span class="row-content_title">合同有效期：</span>
                <span>{{ row.startDate && dayjs(row.startDate).format("YYYY-MM-DD") }} 至 {{ row.endDate && dayjs(row.endDate).format("YYYY-MM-DD") }}</span>
              </div>
              <div class="h-font-md h-flex">
                <span class="row-content_title">服务类型：</span>
                <span>{{ row.productName }}</span>
              </div>
            </div>
            <div class="row-foot h-flex h-row-right">
              <van-button type="primary" size="small" @click="toApply(row)">申请</van-button>
            </div>
          </div>
        </template>
        <template #empty>
          <div class="none-tip">
            <van-image :src="require('@/assets/images/none_data.jpg')" />
            <div>无查询数据！</div>
          </div>
        </template>
      </HYList>
    </div>
  </div>
</template>
<script>
import HYList from "@/components/List/index";
import dayjs from "dayjs";
import { request } from "@/api/service";
export default {
  name: "ProductClaimApplyList",
  components: { HYList },
  data() {
    return {
      spread: true,
      listConfig: {
        initLoad: false,
        offRefresh: true,
        styleObj: {
          minHeight: null
        },
        api: {
          url: "/afis-carservice/contract/coverage/list"
        },
        form: {
          unionQuery: null,
          customerName: null,
          phone: null,
          plateNo: null,
          frameNo: null
        },
        column: []
      }
    };
  },
  computed: {
    plateNo: {
      get: function() {
        return this.listConfig.form.plateNo;
      },
      set: function(val) {
        this.listConfig.form.plateNo = val.toUpperCase();
      }
    },
    frameNo: {
      get: function() {
        return this.listConfig.form.frameNo;
      },
      set: function(val) {
        this.listConfig.form.frameNo = val.toUpperCase();
      }
    }
  },

  methods: {
    dayjs,
    onSearch() {
      let searchCount = 0;
      if (this.listConfig.form.customerName) {
        searchCount += 1;
      }
      if (this.listConfig.form.phone) {
        if (!/^((0\d{2,3}\d{7,8})|(1[3456789]\d{9}))$/.test(this.listConfig.form.phone)) {
          this.$toast("手机号格式有误");
          return false;
        }
        searchCount += 1;
      }
      if (this.listConfig.form.plateNo) {
        if (
          !/^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领])|(临[A-Z0-9]{6}))|新车$/.test(
            this.listConfig.form.plateNo
          )
        ) {
          this.$toast("车牌号格式有误");
          return false;
        }
        searchCount += 1;
      }
      if (this.listConfig.form.frameNo) {
        searchCount += 1;
      }
      if (searchCount > 1) {
        this.spread = false;
        this.$refs.HYList.onRefresh();
        if (this.$refs.searchRef) {
          this.$refs.searchRef.toggle(false);
        }
      } else {
        this.$notify("请输入至少两个查询条件");
      }
    },
    toApply(row) {
      request({
        url: "/afis-carservice/claim/checkBenefits",
        method: "POST",
        data: {
          contractId: row.contractId
        }
      }).then(() => {
        this.$router.push({
          path: "/product/claim/apply/base",
          query: {
            productCode: row.productCode,
            contractId: row.contractId,
            coverageId: row.coverageId
          }
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.row {
  padding: 10px 0;
  &-head {
    font-size: $font-size-lg;
    padding: 6px 20px;
    position: relative;
    &::after {
      @include hairline-bottom($border-color, $padding-xs, $padding-xs, $border-width-xs);
    }
  }
  &-content {
    padding: 6px 20px;
    background-repeat: no-repeat;
    background-position: 90% center;
    background-size: 70px;
    div {
      padding: 2px 0;
    }
    &_title {
      color: #8c8c8c;
      white-space: nowrap;
      text-align: right;
    }
  }
  &-foot {
    border-top: 2px solid $border-color;
    padding: 6px 20px;
    .van-button {
      margin-right: 5px;
    }
  }
}
.none-tip {
  margin: 10px;
  border-radius: 5px;
  background-color: $white;
  ::v-deep .van-image {
    margin-top: 20px;
  }
}
::v-deep .van-pull-refresh {
  min-height: calc(100vh - 48px);
}
.spread ::v-deep {
  .van-pull-refresh {
    min-height: calc(100vh - 196px);
  }
}
</style>
